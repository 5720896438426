import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import { getAllTransactions } from '../utils/firestore';
import Navigation from '../components/Navigation';
import LoadingSpinner from '../components/LoadingSpinner';
import { currencies } from '../utils/currencies';

function Transactions() {
  const [user] = useAuthState(auth);
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage, setTransactionsPerPage] = useState(10);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (user) {
      fetchTransactions();
    }
  }, [user, currentPage, transactionsPerPage]);

  const fetchTransactions = async () => {
    try {
      setLoading(true);
      const { transactions, total } = await getAllTransactions(user.uid, currentPage, transactionsPerPage);
      setTransactions(transactions);
      setTotalTransactions(total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError("Failed to fetch transactions. Please try again.");
      setLoading(false);
    }
  };

  const handleTransactionsPerPageChange = (e) => {
    setTransactionsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalTransactions / transactionsPerPage);

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">Transactions</h1>
        
        <div className="mb-4 flex justify-between items-center">
          <select
            value={transactionsPerPage}
            onChange={handleTransactionsPerPageChange}
            className="block w-40 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
          </select>
          <p className="text-gray-600 dark:text-gray-300">
            Page {currentPage} of {totalPages}
          </p>
        </div>

        {loading ? (
          <LoadingSpinner />
        ) : error ? (
          <p className="text-red-500 dark:text-red-400">{error}</p>
        ) : transactions.length > 0 ? (
          <ul className="divide-y divide-gray-200 dark:divide-gray-700">
            {transactions.map((transaction) => (
              <li key={transaction.id} className="py-4">
                <div className="flex justify-between items-center">
                  <div>
                    <p className="text-sm font-medium text-gray-800 dark:text-white">{transaction.description}</p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">{transaction.date} - {transaction.category}</p>
                  </div>
                  <div className="flex items-center">
                    <p className={`text-sm font-medium ${transaction.type === 'expense' ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400'}`}>
                      {currencies.find(c => c.code === transaction.currency)?.symbol || '$'}
                      {transaction.amount.toFixed(2)}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 dark:text-gray-400">No transactions found.</p>
        )}

        <div className="mt-4 flex justify-between">
          <button
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            Previous
          </button>
          <button
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 dark:bg-gray-700 dark:text-white dark:border-gray-600 dark:hover:bg-gray-600 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default Transactions;