import React from 'react';
import { PencilIcon, TrashIcon, BanknotesIcon } from '@heroicons/react/24/outline';

function TransactionsList({ transactions, onEdit, onDelete, getCurrencySymbol }) {
  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6 mt-6">
      <div className="flex items-center mb-4">
        <BanknotesIcon className="h-6 w-6 text-indigo-500 mr-2" />
        <h2 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-white">Recent Transactions</h2>
      </div>
      {transactions.length > 0 ? (
        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
          {transactions.map((transaction) => (
            <li key={transaction.id} className="py-4">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                <div className="mb-2 sm:mb-0">
                  <p className="text-sm font-medium text-gray-800 dark:text-white">{transaction.description}</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">{transaction.date} - {transaction.category}</p>
                </div>
                <div className="flex items-center">
                  <p className={`text-sm font-medium ${transaction.type === 'expense' ? 'text-red-600 dark:text-red-400' : 'text-green-600 dark:text-green-400'} mr-4`}>
                    {getCurrencySymbol(transaction.currency)}
                    {transaction.amount.toFixed(2)}
                  </p>
                  <button
                    onClick={() => onEdit(transaction)}
                    className="text-indigo-600 dark:text-indigo-400 hover:text-indigo-900 dark:hover:text-indigo-300 mr-2"
                  >
                    <PencilIcon className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => onDelete(transaction.id)}
                    className="text-gray-800 dark:text-gray-200 hover:text-gray-900 dark:hover:text-gray-100"
                  >
                    <TrashIcon className="h-5 w-5" />
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 dark:text-gray-400">No recent transactions</p>
      )}
    </div>
  );
}

export default TransactionsList;