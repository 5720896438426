import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getUserSettings } from '../utils/firestore';

function TransactionForm({ onSubmit, userId }) {
  const [type, setType] = useState('expense');
  const [amount, setAmount] = useState('');
  const [category, setCategory] = useState('');
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!userId) {
        console.error("TransactionForm: No userId provided");
        return;
      }
      try {
        const settings = await getUserSettings(userId);
        setCategories(settings.categories || []);
      } catch (error) {
        console.error("Error fetching user settings:", error);
        setCategories([]);
      }
    };

    fetchCategories();
  }, [userId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      type,
      amount: parseFloat(amount),
      category,
      date: date.toISOString().split('T')[0],
      description
    });
    resetForm();
  };

  const resetForm = () => {
    setType('expense');
    setAmount('');
    setCategory('');
    setDate(new Date());
    setDescription('');
  };


  const inputClasses = "mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:border-indigo-500";

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Type</label>
        <select
          value={type}
          onChange={(e) => setType(e.target.value)}
          className={inputClasses}
        >
          <option value="expense">Expense</option>
          <option value="income">Income</option>
        </select>
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Amount</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className={inputClasses}
          step="0.01"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Category</label>
        {type === 'expense' ? (
          <>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              required
              className={inputClasses}
            >
              <option value="">Select a category</option>
              {categories.map((cat) => (
                <option key={cat} value={cat}>{cat}</option>
              ))}
            </select>
            {categories.length === 0 && (
              <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                No categories available. 
                <Link to="/settings" className="ml-1 text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 dark:hover:text-indigo-300">
                  Add categories in Settings
                </Link>
              </p>
            )}
          </>
        ) : (
          <input
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            className={inputClasses}
            placeholder="Enter income category"
          />
        )}
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Date</label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          className={`${inputClasses} react-datepicker-custom`}
          dateFormat="yyyy-MM-dd"
        />
      </div>
      
      <div>
        <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Description</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={inputClasses}
          rows="3"
        ></textarea>
      </div>
      
      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-offset-gray-800"
        >
          Add Transaction
        </button>
      </div>
    </form>
  );
}

export default TransactionForm;