import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import { updateUserSettings, getUserSettings } from '../utils/firestore';
import Navigation from '../components/Navigation';
import { useTransactions } from '../contexts/TransactionContext';
import { currencies } from '../utils/currencies';
import toast, { Toaster } from 'react-hot-toast';

function Settings() {
  const [user] = useAuthState(auth);
  const [currency, setCurrency] = useState('USD');
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const { refreshMonthlyData } = useTransactions();

  useEffect(() => {
    if (user) {
      loadUserSettings();
    }
  }, [user]);

  const loadUserSettings = async () => {
    const settings = await getUserSettings(user.uid);
    if (settings) {
      setCurrency(settings.currency || 'USD');
      setCategories(settings.categories || []);
    }
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const handleAddCategory = () => {
    if (newCategory && !categories.includes(newCategory)) {
      setCategories([...categories, newCategory]);
      setNewCategory('');
    }
  };

  const handleRemoveCategory = (category) => {
    setCategories(categories.filter(c => c !== category));
  };

  const handleSaveSettings = async () => {
    await updateUserSettings(user.uid, { currency, categories });
    refreshMonthlyData(user.uid);
    toast.success('Settings saved successfully!');
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">Settings</h1>
        
        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">User Profile</h2>
          <div className="flex items-center mb-4">
            <img src={user.photoURL} alt="Profile" className="w-16 h-16 rounded-full mr-4" />
            <div>
              <p className="text-gray-800 dark:text-white">{user.displayName}</p>
              <p className="text-gray-600 dark:text-gray-300">{user.email}</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Currency</h2>
          <select
            value={currency}
            onChange={handleCurrencyChange}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            {currencies.map((curr) => (
              <option key={curr.code} value={curr.code}>
                {curr.name} ({curr.symbol})
              </option>
            ))}
          </select>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Expense Categories</h2>
          <div className="flex mb-4">
            <input
              type="text"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
              className="flex-grow mr-2 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
              placeholder="New category"
            />
            <button
              onClick={handleAddCategory}
              className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add
            </button>
          </div>
          <ul className="space-y-2">
            {categories.map((category) => (
              <li key={category} className="flex justify-between items-center">
                <span className="text-gray-800 dark:text-white">{category}</span>
                <button
                  onClick={() => handleRemoveCategory(category)}
                  className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        <button
          onClick={handleSaveSettings}
          className="w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save Settings
        </button>
      </div>
      <Toaster 
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
        containerStyle={{
          top: 80, 
        }}
      />
    </div>
  );
}

export default Settings;