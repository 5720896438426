import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useTransactions } from '../contexts/TransactionContext';
import { currencies } from '../utils/currencies';

function MonthlyOverview() {
  const { monthlyData, userCurrency } = useTransactions();
  const currencySymbol = currencies.find(c => c.code === userCurrency)?.symbol || '$';

  const chartData = monthlyData
    .map(item => ({
      month: item.month,
      Income: item.income,
      Expenses: item.expense
    }))
    .reverse()
    .slice(0, 6);

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          data={chartData}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" tick={{ fontSize: 12 }} />
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => `${currencySymbol}${value}`}
          />
          <Tooltip
            formatter={(value, name) => [`${currencySymbol}${value.toFixed(2)}`, name]}
          />
          <Legend />
          <Bar dataKey="Income" fill="#4ADE80" />
          <Bar dataKey="Expenses" fill="#F87171" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MonthlyOverview;