import React from 'react';
import { useTransactions } from '../contexts/TransactionContext';
import { ArrowTrendingUpIcon, ArrowTrendingDownIcon } from '@heroicons/react/24/outline';
import { currencies } from '../utils/currencies';

function MonthlySummary() {
  const { monthlyData, userCurrency } = useTransactions();
  const currentMonth = monthlyData[0] || { income: 0, expense: 0 };
  
  const currencySymbol = currencies.find(c => c.code === userCurrency)?.symbol || '$';

  const formatCurrency = (amount) => {
    return `${currencySymbol}${amount.toFixed(2)}`;
  };

  return (
    <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6">
      <h2 className="text-lg sm:text-xl font-semibold mb-4 text-gray-800 dark:text-white">This Month</h2>
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ArrowTrendingUpIcon className="h-6 w-6 sm:h-8 sm:w-8 text-green-500 mr-2" />
            <span className="text-sm sm:text-base font-medium text-gray-500 dark:text-gray-400">Income</span>
          </div>
          <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white">
            {formatCurrency(currentMonth.income)}
          </span>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <ArrowTrendingDownIcon className="h-6 w-6 sm:h-8 sm:w-8 text-red-500 mr-2" />
            <span className="text-sm sm:text-base font-medium text-gray-500 dark:text-gray-400">Expenses</span>
          </div>
          <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white">
            {formatCurrency(currentMonth.expense)}
          </span>
        </div>
        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center justify-between">
            <span className="text-sm sm:text-base font-medium text-gray-500 dark:text-gray-400">Balance</span>
            <span className="text-base sm:text-lg font-semibold text-gray-800 dark:text-white">
              {formatCurrency(currentMonth.income - currentMonth.expense)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MonthlySummary;