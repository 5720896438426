import React, { useEffect } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './utils/firebase';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuth } from './hooks/useAuth';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import YearSummary from './pages/YearSummary';
import Transactions from './pages/Transactions';  // Add this import
import { ThemeProvider } from './contexts/ThemeContext';
import { TransactionProvider } from './contexts/TransactionContext';
import InstallPrompt from './components/InstallPrompt';
import './styles/datepicker-custom.css';

function App() {
  const [user, setUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    // Track app usage
    const usageCount = parseInt(localStorage.getItem('usageCount') || '0');
    localStorage.setItem('usageCount', (usageCount + 1).toString());

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider>
      <TransactionProvider>
        <Router>
          <Routes>
            <Route path="/" element={user ? <Navigate to="/dashboard" replace /> : <Login />} />
            <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/" replace />} />
            <Route path="/settings" element={user ? <Settings /> : <Navigate to="/" replace />} />
            <Route path="/summary" element={user ? <YearSummary /> : <Navigate to="/" replace />} />
            <Route path="/transactions" element={user ? <Transactions /> : <Navigate to="/" replace />} />  {/* Add this line */}
          </Routes>
          <InstallPrompt />
        </Router>
      </TransactionProvider>
    </ThemeProvider>
  );
}

export default App;