import { collection, addDoc, query, where, orderBy, limit, getDocs, doc, updateDoc, deleteDoc , getDoc, setDoc} from 'firebase/firestore';
import { startAfter, getCountFromServer } from 'firebase/firestore';

import { db } from './firebase';

// Add a new transaction
export const addTransaction = async (userId, transaction) => {
  try {
    const docRef = await addDoc(collection(db, 'transactions'), {
      userId,
      ...transaction,
      createdAt: new Date()
    });
    console.log("Transaction added with ID: ", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error adding transaction: ", error);
    throw error;
  }
};

// Get recent transactions for a user
export const getRecentTransactions = async (userId, limitCount = 5) => {
  try {
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      orderBy('date', 'desc'),
      limit(limitCount)
    );

    const querySnapshot = await getDocs(q);
    const transactions = [];
    querySnapshot.forEach((doc) => {
      transactions.push({ id: doc.id, ...doc.data() });
    });

    return transactions;
  } catch (error) {
    console.error("Error getting recent transactions: ", error);
    throw error;
  }
};

// Update a transaction
export const updateTransaction = async (transactionId, updates) => {
  try {
    const transactionRef = doc(db, 'transactions', transactionId);
    await updateDoc(transactionRef, updates);
    console.log("Transaction updated successfully");
  } catch (error) {
    console.error("Error updating transaction: ", error);
    throw error;
  }
};

// Delete a transaction
export const deleteTransaction = async (transactionId) => {
  try {
    const transactionRef = doc(db, 'transactions', transactionId);
    await deleteDoc(transactionRef);
    console.log("Transaction deleted successfully");
  } catch (error) {
    console.error("Error deleting transaction: ", error);
    throw error;
  }
};

// Get monthly transactions for a user
export const getMonthlyTransactions = async (userId) => {
  try {
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      orderBy('date', 'desc')
    );

    const querySnapshot = await getDocs(q);
    const transactions = [];
    querySnapshot.forEach((doc) => {
      transactions.push({ id: doc.id, ...doc.data() });
    });

    // Process transactions into monthly data
    const monthlyData = transactions.reduce((acc, transaction) => {
      const date = new Date(transaction.date);
      const monthYear = `${date.getFullYear()}-${date.getMonth()}`;
      
      if (!acc[monthYear]) {
        acc[monthYear] = { 
          month: date.toLocaleString('default', { month: 'short' }),
          year: date.getFullYear(),
          income: 0,
          expense: 0
        };
      }
      
      if (transaction.type === 'income') {
        acc[monthYear].income += transaction.amount;
      } else {
        acc[monthYear].expense += transaction.amount;
      }
      
      return acc;
    }, {});

    const monthlyDataArray = Object.values(monthlyData)
      .sort((a, b) => new Date(b.year, b.month) - new Date(a.year, a.month))
      .slice(0, 12);

    return {
      monthlyData: monthlyDataArray,
      transactions: transactions  // Make sure to return the transactions array
    };
  } catch (error) {
    console.error("Error getting monthly transactions: ", error);
    throw error;
  }
};

export const getUserSettings = async (userId) => {
  if (!userId) {
    console.error("getUserSettings called with no userId");
    return { currency: 'USD', categories: [] };
  }

  try {
    const docRef = doc(db, 'userSettings', userId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // If the document doesn't exist, create it with default values
      const defaultSettings = { currency: 'USD', categories: [] };
      await setDoc(docRef, defaultSettings);
      return defaultSettings;
    }
  } catch (error) {
    console.error("Error getting user settings:", error);
    return { currency: 'USD', categories: [] };
  }
};

export const getYearlyTransactions = async (userId) => {
  const currentYear = new Date().getFullYear();
  const startOfYear = new Date(currentYear, 0, 1).toISOString();
  const endOfYear = new Date(currentYear, 11, 31).toISOString();

  try {
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      where('date', '>=', startOfYear),
      where('date', '<=', endOfYear)
    );

    const querySnapshot = await getDocs(q);
    let totalIncome = 0;
    let totalExpense = 0;
    const categories = {};
    const monthlyData = Array(12).fill().map((_, i) => ({
      month: new Date(currentYear, i).toLocaleString('default', { month: 'short' }),
      income: 0,
      expense: 0
    }));

    querySnapshot.forEach((doc) => {
      const transaction = doc.data();
      const date = new Date(transaction.date);
      const month = date.getMonth();

      if (transaction.type === 'income') {
        totalIncome += transaction.amount;
        monthlyData[month].income += transaction.amount;
      } else {
        totalExpense += transaction.amount;
        monthlyData[month].expense += transaction.amount;
        categories[transaction.category] = (categories[transaction.category] || 0) + transaction.amount;
      }
    });

    return {
      income: totalIncome,
      expense: totalExpense,
      categories,
      monthlyData
    };
  } catch (error) {
    console.error("Error getting yearly transactions: ", error);
    throw error;
  }
};


export const updateUserSettings = async (userId, settings) => {
  try {
    const docRef = doc(db, 'userSettings', userId);
    await updateDoc(docRef, settings);
  } catch (error) {
    console.error("Error updating user settings: ", error);
    throw error;
  }
};

export const getAllTransactions = async (userId, page, perPage) => {
  try {
    const transactionsRef = collection(db, 'transactions');
    let q = query(
      transactionsRef,
      where('userId', '==', userId),
      orderBy('date', 'desc'),
      limit(perPage)
    );

    if (page > 1) {
      const prevPageQuery = query(
        transactionsRef,
        where('userId', '==', userId),
        orderBy('date', 'desc'),
        limit((page - 1) * perPage)
      );
      const prevPageSnapshot = await getDocs(prevPageQuery);
      const lastVisible = prevPageSnapshot.docs[prevPageSnapshot.docs.length - 1];
      q = query(q, startAfter(lastVisible));
    }

    const snapshot = await getDocs(q);
    const transactions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    const countSnapshot = await getCountFromServer(query(transactionsRef, where('userId', '==', userId)));
    const totalCount = countSnapshot.data().count;

    return { transactions, total: totalCount };
  } catch (error) {
    console.error("Error getting all transactions: ", error);
    throw error;
  }
};

export const getMonthSoFarTransactions = async (userId) => {
  const today = new Date();
  const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  
  try {
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      where('date', '>=', startOfMonth.toISOString().split('T')[0]),
      where('date', '<=', today.toISOString().split('T')[0]),
      orderBy('date', 'asc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting month-to-date transactions: ", error);
    throw error;
  }
};


