import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import { auth } from '../utils/firebase';
import DarkModeToggle from './DarkModeToggle';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';

function Navigation() {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="bg-gray-800 dark:bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/dashboard" className="text-xl font-bold">ExpenseTracker</Link>
          </div>
          <div className="hidden md:block">
            <div className="ml-10 flex items-baseline space-x-4">
              <Link to="/dashboard" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Dashboard</Link>
              <Link to="/transactions" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Transactions</Link>
              <Link to="/summary" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Year Summary</Link>
              <Link to="/settings" className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Settings</Link>
              <button onClick={handleLogout} className="hover:bg-gray-700 px-3 py-2 rounded-md text-sm font-medium">Logout</button>
              <DarkModeToggle />
            </div>
          </div>
          <div className="md:hidden flex items-center">
            <DarkModeToggle />
            <button onClick={toggleMenu} className="ml-2 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/dashboard" onClick={closeMenu} className="block hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Dashboard</Link>
            <Link to="/transactions" onClick={closeMenu} className="block hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Transactions</Link>
            <Link to="/summary" onClick={closeMenu} className="block hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Year Summary</Link>
            <Link to="/settings" onClick={closeMenu} className="block hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Settings</Link>
            <button onClick={() => { handleLogout(); closeMenu(); }} className="w-full text-left hover:bg-gray-700 px-3 py-2 rounded-md text-base font-medium">Logout</button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default Navigation;