import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';

function AddTransactionButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full flex items-center"
    >
      <PlusIcon className="h-5 w-5 mr-2" />
      Add Transaction
    </button>
  );
}

export default AddTransactionButton;