import React, { createContext, useState, useContext, useCallback } from 'react';
import { addTransaction, updateTransaction, deleteTransaction, getMonthlyTransactions, getUserSettings } from '../utils/firestore';

const TransactionContext = createContext();

export function TransactionProvider({ children }) {
  const [monthlyData, setMonthlyData] = useState([]);
  const [monthSoFarData, setMonthSoFarData] = useState([]);
  const [userCurrency, setUserCurrency] = useState('USD');

  const refreshMonthlyData = useCallback(async (userId) => {
    try {
      const settings = await getUserSettings(userId);
      setUserCurrency(settings.currency || 'USD');

      const data = await getMonthlyTransactions(userId);
      setMonthlyData(data.monthlyData);
      
      // Process month-so-far data
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const currentYear = currentDate.getFullYear();
      
      const currentMonthTransactions = data.transactions.filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate.getMonth() === currentMonth && transactionDate.getFullYear() === currentYear;
      });

      const dailyTotals = currentMonthTransactions.reduce((acc, transaction) => {
        const day = new Date(transaction.date).getDate();
        if (!acc[day]) acc[day] = 0;
        if (transaction.type === 'expense') {
          acc[day] += transaction.amount;
        }
        return acc;
      }, {});

      let runningTotal = 0;
      const monthSoFar = Object.entries(dailyTotals)
        .map(([day, total]) => {
          runningTotal += total;
          return { day: parseInt(day), total: runningTotal };
        })
        .sort((a, b) => a.day - b.day);

      setMonthSoFarData(monthSoFar);
    } catch (error) {
      console.error("Error refreshing monthly data:", error);
    }
  }, []);

  const addNewTransaction = useCallback(async (userId, transaction) => {
    await addTransaction(userId, transaction);
    await refreshMonthlyData(userId);
  }, [refreshMonthlyData]);

  const editTransaction = useCallback(async (userId, transactionId, updatedTransaction) => {
    await updateTransaction(transactionId, updatedTransaction);
    await refreshMonthlyData(userId);
  }, [refreshMonthlyData]);

  const removeTransaction = useCallback(async (userId, transactionId) => {
    await deleteTransaction(transactionId);
    await refreshMonthlyData(userId);
  }, [refreshMonthlyData]);

  return (
    <TransactionContext.Provider value={{
      monthlyData,
      monthSoFarData,
      userCurrency,
      refreshMonthlyData,
      addNewTransaction,
      editTransaction,
      removeTransaction
    }}>
      {children}
    </TransactionContext.Provider>
  );
}

export function useTransactions() {
  return useContext(TransactionContext);
}