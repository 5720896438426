import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, browserSessionPersistence, setPersistence } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyA-5ld-7-_MzTl6NwsfXd-y3pUUMLq7DVA",
  authDomain: "mithila-2eeab.firebaseapp.com",
  projectId: "mithila-2eeab",
  storageBucket: "mithila-2eeab.appspot.com",
  messagingSenderId: "967083007655",
  appId: "1:967083007655:web:463637ffb4527cc5e80582",
  measurementId: "G-M2MQN15RVZ"
};



const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
setPersistence(auth, browserSessionPersistence)
  .then(() => {
    console.log("Persistence set to browserSessionPersistence");
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
  });

const googleProvider = new GoogleAuthProvider();
const db = getFirestore(app);
export { auth, googleProvider, db };