import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import { getYearlyTransactions } from '../utils/firestore';
import { useTransactions } from '../contexts/TransactionContext';
import { currencies } from '../utils/currencies';
import Navigation from '../components/Navigation';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

function YearSummary() {
  const [user] = useAuthState(auth);
  const { userCurrency } = useTransactions();
  const [yearlyData, setYearlyData] = useState({ income: 0, expense: 0, categories: {} });
  const currencySymbol = currencies.find(c => c.code === userCurrency)?.symbol || '$';

  useEffect(() => {
    if (user) {
      fetchYearlyData();
    }
  }, [user]);

  const fetchYearlyData = async () => {
    const data = await getYearlyTransactions(user.uid);
    setYearlyData(data);
  };

  const formatCurrency = (amount) => `${currencySymbol}${amount.toFixed(2)}`;

  const categoryData = Object.entries(yearlyData.categories).map(([category, amount]) => ({
    name: category,
    value: amount
  }));

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82CA9D'];

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-gray-800 dark:text-white">Year Summary</h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Total Income</h2>
            <p className="text-3xl font-bold text-green-500">{formatCurrency(yearlyData.income)}</p>
          </div>
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Total Expenses</h2>
            <p className="text-3xl font-bold text-red-500">{formatCurrency(yearlyData.expense)}</p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6 mb-8">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Monthly Breakdown</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={yearlyData.monthlyData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis tickFormatter={(value) => `${currencySymbol}${value}`} />
                <Tooltip formatter={(value) => [`${formatCurrency(value)}`, 'Amount']} />
                <Legend />
                <Bar dataKey="income" name="Income" fill="#4ADE80" />
                <Bar dataKey="expense" name="Expense" fill="#F87171" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Expense Categories</h2>
          <div className="h-80">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={categoryData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                >
                  {categoryData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => formatCurrency(value)} />
              </PieChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default YearSummary;