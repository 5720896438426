import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../utils/firebase';
import { getRecentTransactions } from '../utils/firestore';
import { useTransactions } from '../contexts/TransactionContext';
import TransactionForm from '../components/TransactionForm';
import EditTransactionForm from '../components/EditTransactionForm';
import Navigation from '../components/Navigation';
import MonthlyOverview from '../components/MonthlyOverview';
import MonthSoFarGraph from '../components/MonthSoFarGraph';
import MonthlySummary from '../components/MonthlySummary';
import Modal from '../components/Modal';
import DashboardHeader from '../components/dashboard/DashboardHeader';
import AddTransactionButton from '../components/dashboard/AddTransactionButton';
import TransactionsList from '../components/dashboard/TransactionsList';
import { currencies } from '../utils/currencies';
import toast, { Toaster } from 'react-hot-toast';

function Dashboard() {
  const [user] = useAuthState(auth);
  const [transactions, setTransactions] = useState([]);
  const [editingTransaction, setEditingTransaction] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { addNewTransaction, editTransaction, removeTransaction, refreshMonthlyData } = useTransactions();

  useEffect(() => {
    if (user) {
      fetchRecentTransactions();
      refreshMonthlyData(user.uid);
    }
  }, [user, refreshMonthlyData]);

  const fetchRecentTransactions = async () => {
    try {
      const recentTransactions = await getRecentTransactions(user.uid);
      setTransactions(recentTransactions);
    } catch (error) {
      console.error("Error fetching recent transactions:", error);
      toast.error('Failed to fetch recent transactions');
    }
  };

  const handleTransactionSubmit = async (transaction) => {
    setIsModalOpen(false); // Close the modal immediately
    try {
      await addNewTransaction(user.uid, transaction);
      fetchRecentTransactions();
      toast.success('Transaction saved successfully!');
    } catch (error) {
      console.error("Error adding transaction:", error);
      toast.error('Failed to save transaction. Please try again.');
    }
  };

  const handleUpdateTransaction = async (updatedTransaction) => {
    setEditingTransaction(null); // Close the edit form immediately
    try {
      await editTransaction(user.uid, editingTransaction.id, updatedTransaction);
      fetchRecentTransactions();
      toast.success('Transaction updated successfully!');
    } catch (error) {
      console.error("Error updating transaction:", error);
      toast.error('Failed to update transaction. Please try again.');
    }
  };

  const handleDeleteTransaction = async (transactionId) => {
    if (window.confirm("Are you sure you want to delete this transaction?")) {
      try {
        await removeTransaction(user.uid, transactionId);
        fetchRecentTransactions();
        toast.success('Transaction deleted successfully!');
      } catch (error) {
        console.error("Error deleting transaction:", error);
        toast.error('Failed to delete transaction. Please try again.');
      }
    }
  };

  const getCurrencySymbol = (currencyCode) => {
    const currency = currencies.find(c => c.code === currencyCode);
    return currency ? currency.symbol : '$';
  };

  return (
    <div className="min-h-screen bg-gray-100 dark:bg-gray-900">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-8">
          <DashboardHeader />
          <AddTransactionButton onClick={() => setIsModalOpen(true)} />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-6">
          <MonthlySummary userId={user.uid} />
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6">
            <div className="flex items-center mb-4">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-white">Monthly Overview</h2>
            </div>
            <MonthlyOverview />
          </div>
          <div className="bg-white dark:bg-gray-800 shadow rounded-lg p-4 sm:p-6">
            <div className="flex items-center mb-4">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-800 dark:text-white">Month So Far</h2>
            </div>
            <MonthSoFarGraph />
          </div>
        </div>

        <TransactionsList 
          transactions={transactions}
          onEdit={setEditingTransaction}
          onDelete={handleDeleteTransaction}
          getCurrencySymbol={getCurrencySymbol}
        />

        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Add Transaction</h2>
          {user && <TransactionForm onSubmit={handleTransactionSubmit} userId={user.uid} />}
        </Modal>

        {editingTransaction && (
          <Modal isOpen={true} onClose={() => setEditingTransaction(null)}>
            <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-white">Edit Transaction</h2>
            <EditTransactionForm
              transaction={editingTransaction}
              onSubmit={handleUpdateTransaction}
              onCancel={() => setEditingTransaction(null)}
            />
          </Modal>
        )}
      </div>
      <Toaster 
        position="top-center"
        toastOptions={{
          duration: 3000,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
        containerStyle={{
          top: 80,
        }}
      />
    </div>
  );
}

export default Dashboard;