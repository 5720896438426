import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { useTransactions } from '../contexts/TransactionContext';
import { currencies } from '../utils/currencies';

function MonthSoFarGraph() {
  const { monthSoFarData, userCurrency } = useTransactions();
  const currencySymbol = currencies.find(c => c.code === userCurrency)?.symbol || '$';

  return (
    <div className="w-full h-64">
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={monthSoFarData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            tick={{ fontSize: 12 }}
          />
          <YAxis
            tick={{ fontSize: 12 }}
            tickFormatter={(value) => `${currencySymbol}${value}`}
          />
          <Tooltip
            formatter={(value) => [`${currencySymbol}${value.toFixed(2)}`, 'Total Expenses']}
            labelFormatter={(label) => `Day ${label}`}
          />
          <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default MonthSoFarGraph;